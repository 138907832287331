import React from 'react';
import { Paper, Typography } from '@mui/material';

const ChatMessage = ({ text, isUser }) => {
  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-2`}>
      <Paper
        elevation={2}
        className={`w-fit max-w-[75%] rounded-2xl px-4 py-2 border ${
          isUser ? 'bg-[#003748] text-white border-blue-600' : 'bg-gray-900 text-white border-gray-600'
        }`}
        sx={{
          backgroundColor: isUser ? '#003748' : '#1E1E1E',
          color: 'white',
          borderColor: isUser ? '#0077B6' : '#555',
          '&.MuiPaper-root': { backgroundImage: 'none' }
        }}
      >
        {!isUser && (
          <Typography
            variant="h6"
            component="div"
            className="font-semibold mb-1 text-lg text-gray-300"
            sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}
          >
            Ami
          </Typography>
        )}
        <Typography component="p" className="whitespace-pre-wrap text-base leading-tight" sx={{ fontSize: '1rem' }}>
          {text?.text2}
        </Typography>
      </Paper>
    </div>
  );
};

export default ChatMessage;
